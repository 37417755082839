import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

import Im from "immutable";
import Moment from "moment";

import useApi, { fetcher } from "storybook-dashboard/utils/fetching";

import { Status } from "AppSrc/user/projectItem/reportingPackageStatusItem";
import { listToMap } from "AppSrc/project/metrics/node";

const Notifications = () => {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = [
    {
      name: "Company",
      format: (r) => {
        return (
          <Link className={`font-weight-bold`} to={`/company/${r.project.company.uid}`}>
            <u>{r.project.company.name}</u>
          </Link>
        );
      },
      selector: (r) => {
        return r.project.company.name;
      },
      sortable: true,
    },
    {
      name: "Project",
      format: (r) => {
        return (
          <Link className={`font-weight-bold`} to={`/project/${r.project.uid}`}>
            <u>{r.project.name}</u>
          </Link>
        );
      },
      selector: (r) => {
        return r.project.name;
      },
      sortable: true,
    },
    {
      name: "Reporting Package",
      format: (r) => {
        return (
          <Link className={`font-weight-bold`} to={`/project/${r.project.uid}/wpack/${r.commit.ref}/`}>
            <u>{r.ref.name}</u>
          </Link>
        );
      },
      selector: (r) => {
        return r.ref.name;
      },
      sortable: true,
    },
    {
      name: "Reporting Period",
      format: (r) =>
        `${Moment(r.commit.reporting_period_data.startDate).format("Do MMM YY")} - ${Moment(r.commit.reporting_period_data.endDate).format("Do MMM YY")}`,
      sortable: true,
      selector: (r) => {
        return r.commit.reporting_period_data.startDate;
      },
    },
    {
      name: "Status",
      format: (r) => (
        <Link to={`/project/${r.project.uid}/wpack/${r.commit.ref}/report/${r.commit.uid}`}>
          <Status status={r.commit.status.name} />
        </Link>
      ),
      sortable: true,
      selector: (r) => {
        return r.commit.status.name;
      },
    },
    {
      format: (r) => `${Moment(r.commit.meta_json.lastUpdateOn).format("Do MMM YY")}`,
      name: "Last Updated",
      sortable: true,
      selector: (r) => {
        return r.commit.meta_json.lastUpdateOn;
      },
    },
  ];

  let { data: projects } = useApi(`/api/project/`);
  let { data: refs } = useApi(`/api/tracker/ref/`);
  let { data: commits, mutate: mutateCommits } = useApi(`/api/tracker/commit/`);

  useEffect(() => {
    if (projects && commits && refs) {
      console.log("Notifications useEffect", projects?.toJS(), commits?.toJS());
      let projectsById = projects.reduce((a, p) => a.set(p.get("uid"), p), Im.Map());
      let refsById = refs.reduce((a, p) => a.set(p.get("uid"), p), Im.Map());
      const rows = commits.map((commit) => {
        let project = projectsById.get(commit.get("project"));
        let ref = refsById.get(commit.get("ref"));
        return {
          project,
          commit,
          ref,
        };
      });
      setData(rows.toJS());
    }
  }, [projects, commits, refs]);

  const pendingApprovalSelected = (r) => r.filter((r) => r.commit.status.name === "Pending Approval").length > 0;

  const requiresRevisionSelected = (r) => r.filter((r) => r.commit.status.name === "Requires Revision").length > 0;

  const canApproveOrReject = (r) => pendingApprovalSelected(r) && !requiresRevisionSelected(r);

  const mergeCommits = ({ existing, updated }) => {
    let commitsById = listToMap(existing, "uid");
    let newCommitsById = listToMap(Im.fromJS([updated]), "uid");
    return commitsById.merge(newCommitsById).toList();
  };

  const markSelected = async ({ e, status }) => {
    e.preventDefault();
    const requests = selectedRows.map(({ commit: { tracker, ref, uid } }) => {
      const url = `/api/tracker/${tracker}/ref/${ref}/commit/${uid}/`;
      const data = { status };
      return fetcher(url, "PATCH", data)
        .then(({ payload }) => {
          mutateCommits(mergeCommits({ existing: commits, updated: payload?.toJS() }), undefined, false);
        })
        .catch((err) => {
          alert(err);
        });
    });
    await Promise.all(requests);
  };

  return (
    <div className="col">
      <div className="shadow p-3 mb-5 bg-white rounded">
        <div className="px-2 my-2">
          <h6>Notifications</h6>
        </div>
        <div style={{ width: "100%", height: "700px" }}>
          <div style={{ height: "100%", width: "100%" }} className={"ag-theme-quartz"}>
            <form>
              <DataTable
                columns={columns}
                data={data}
                striped={true}
                dense={true}
                selectableRows
                pagination
                onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
              />
              <button
                type="Submit"
                className="btn btn-sm btn-primary"
                onClick={(e) => markSelected({ e, status: "APPROVE" })}
                disabled={!canApproveOrReject(selectedRows)}
              >
                Approve
              </button>
              <button
                type="Submit"
                className="btn btn-sm btn-primary"
                onClick={(e) => markSelected({ e, status: "UPDATE" })}
                disabled={!canApproveOrReject(selectedRows)}
              >
                Reject
              </button>
              {!pendingApprovalSelected(selectedRows) && (
                <p>Select "Pending Approval" reports to Accept or Reject them</p>
              )}
              {requiresRevisionSelected(selectedRows) && (
                <p>Reports which "Require Revision" cannot be Accepted or Rejected, please unselect them</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
